<template>
  <v-form ref="serviceSupplierForm">
    <v-card class="pa-6 my-8">
      <AdsSelect
        v-model="supplier.supplierType"
        :items="suppliertypeitems"
        class="mb-0"
        outlined
        label="Supplier Type"
        placeholder="Please select..."
        :rules="[validators.required]"
        :readonly="isReadOnlyUser"
      />
      <AdsTextField
        v-model="supplier.supplierCode"
        label="Supplier Code"
        maxlength="10"
        :rules="[validators.required, validators.alphaNumeric,validators.minMaxString(1, 10)]"
        :readonly="isReadOnlyUser"
        :disabled="isView"
      />
      <AdsTextField
        v-model="supplier.name"
        label="Name"
        maxlength="200"
        :rules="[validators.required, validators.name,validators.minMaxString(1, 200)]"
        :readonly="isReadOnlyUser"
      />
      <AdsTextField
        v-model="supplier.website"
        label="Website"
        maxlength="500"
        :rules="[validators.required, validators.isValidURL]"
        :readonly="isReadOnlyUser"
        hint="E.g., https://example.com"
      />
      <AdsTextField
        v-model="domains"
        label="Domain"
        maxlength="200"
        :rules="[validators.required, validators.maxItems,validators.minMaxString(1, 200)]"
        :readonly="isReadOnlyUser"
      />
      <AdsSelect
        v-model="supplier.status"
        :items="statusitems"
        class="mb-0"
        outlined
        label="Status"
        placeholder="Please select..."
        :rules="[validators.required]"
        :readonly="isReadOnlyUser"
      />
    </v-card>
    <v-card class="pa-6 my-8">
      <h2 class="section-heading mb-8">Contract contact details</h2>
      <AdsTextField
        v-model="contractContactDetails.name"
        label="Contact Name"
        :rules="[validators.required, validators.name, validators.minMaxString(1, 200)]"
        :readonly="isReadOnlyUser"
      />
      <AdsTextField
        v-model="contractContactDetails.number"
        label="Phone Number"
        :rules="[validators.required, validators.phone, validators.minMaxString(9, 200)]"
        :readonly="isReadOnlyUser"
      />
      <AdsTextField
        v-model="contractContactDetails.emailAddress"
        label="Email Address"
        :rules="[validators.required, validators.email, validators.minMaxString(1, 200)]"
        :readonly="isReadOnlyUser"
      />
    </v-card>
    <v-card class="pa-6 my-8">
      <h2 class="section-heading mb-8">Technical contact details</h2>
      <AdsTextField
        v-model="technicalContactDetails.name"
        label="Contact Name"
        :rules="[validators.required, validators.name, validators.minMaxString(1, 200)]"
        :readonly="isReadOnlyUser"
      />
      <AdsTextField
        v-model="technicalContactDetails.number"
        label="Phone Number"
        :rules="[validators.required, validators.phone, validators.minMaxString(9, 200)]"
        :readonly="isReadOnlyUser"
      />
      <AdsTextField
        v-model="technicalContactDetails.emailAddress"
        label="Email Address"
        :rules="[validators.required, validators.email, validators.minMaxString(1, 200)]"
        :readonly="isReadOnlyUser"
      />
    </v-card>
    <AdsButton
      :primary="true"
      buttonText="Save"
      class="mr-4"
      @click="save"
      :disabled="isReadOnlyUser"
    />
    <AdsButton
      :primary="true"
      buttonText="Cancel"
      @click="cancel"
    />
  </v-form>
</template>
<script>
import { AdsTextField, AdsSelect, AdsButton } from '@nswdoe/doe-ui-core'
import { DEFAULT_SUPPLIER } from '@/constants/initial'
import validators from '@/helpers/validators'
import { getFormErrors } from '@/helpers/form'
import { cloneDeep } from 'lodash'

export default {
  name: 'AddServiceSuppliers',
  components: {
    AdsTextField,
    AdsSelect,
    AdsButton,
  },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    initialSupplier: {
      type: Object,
      default: cloneDeep(DEFAULT_SUPPLIER),
    },
  },
  data() {
    // convert contacts to contractContactDetails & technicalContactDetails
    let contractContactDetails = this.initialSupplier.contacts.filter(
      (c) => c.type === 'Contract'
    )[0]
    let technicalContactDetails = this.initialSupplier.contacts.filter(
      (c) => c.type === 'Technical'
    )[0]

    return {
      supplier: this.initialSupplier,
      contractContactDetails,
      technicalContactDetails,
      domains: this.initialSupplier.emailDomains.join(','),
      errors: [],
      showErrors: false,
      statusitems: ['Active', 'Deprecated'],
      suppliertypeitems: ['AMPS', 'NON-AMPS'],
      validators: {
        required: validators.required,
        name: validators.name,
        phone: validators.phone,
        email: validators.email,
        alphaNumeric: validators.alphaNumeric,
        isValidURL: validators.isValidURL,
        maxItems: validators.maxItems,
        minMaxString: validators.minMaxString,
      },
    }
  },

  computed: {
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
  },

  methods: {
    cancel() {
      this.$router.push('/serviceregistry/servicesuppliers')
    },
    save() {
      this.validate()
      this.errors = getFormErrors(this.$refs.serviceSupplierForm)

      if (this.errors && this.errors.length > 0) {
        this.showErrors = true
      } else {
        // No validation errors
        // convert contractContactDetails & technicalContactDetails to contacts
        const contacts = [
          { ...this.contractContactDetails, type: 'Contract' },
          { ...this.technicalContactDetails, type: 'Technical' },
        ]
        this.supplier.contacts = contacts
        this.supplier.emailDomains = this.domains.split(',')
        this.$emit('save', this.supplier)
      }
    },
    validate() {
      if (this.$refs.serviceSupplierForm) {
        this.$refs.serviceSupplierForm.validate()
      }
    },
  }
}
</script>
